<template>
  <div class="loading-container">
    <Vue3Lottie :animationData="lottieAnimation" width="100px" height="100px" />
    <p class="loading-text"> <span>{{ randomLoadingTextWithoutEllipsis }}</span> <span class="ellipsis"> <span
          class="dot">.</span> <span class="dot">.</span> <span class="dot">.</span> </span> </p>
  </div>
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie';
import lottieAnimation from '~/assets/lotties/loading/animations/loading.json';

export default {
  components: {
    Vue3Lottie
  },
  data() {
    return {
      loadingText: [
        "Reticulating splines",
        "Summoning dragons",
        "Counting backwards from infinity",
        "Warming up the coffee machine",
        "Loading the loading message",
        "Spinning up the hamster wheel",
        "Consulting the oracle",
        "Stretching pixels"
      ],
      lottieAnimation: lottieAnimation
    };
  },
  computed: {
    randomLoadingTextWithoutEllipsis() {
      return this.loadingText[Math.floor(Math.random() * this.loadingText.length)];
    }
  },
}
</script>

<style scoped>
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh;
  background-color: rgba(255, 255, 255, 0);
}

.loading-text {
  margin-top: 10px;
  font-size: 18px;
  letter-spacing: 1px;
}

.ellipsis {
  display: inline-flex;
}

.dot {
  animation: dotAnimation 1.5s infinite;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes dotAnimation {

  0%,
  40%,
  100% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }
}
</style>