<template>
    <div class="button-container">
        <ion-button class="ion-margin-top ion-margin-bottom clear-button" mode="md" color="clear"
            href="https://play.google.com/store/apps/details?id=com.cloudcompany.arkitect&hl=en_US">
            <GlobalIcon :iconName="logoGooglePlaystore" :size="'large'" />
        </ion-button>
        <ion-button class="ion-margin-top ion-margin-bottom clear-button" mode="md" color="clear"
            href="https://apps.apple.com/us/app/arkitect-get-tech-certified/id6477337782">
            <GlobalIcon :iconName="logoAppleAppstore" :size="'large'" />
        </ion-button>
    </div>
</template>

<script lang="ts">
import GlobalIcon from './GlobalIcon.vue';
import { logoGooglePlaystore, logoAppleAppstore, globe } from 'ionicons/icons';

export default {
    components: {
        GlobalIcon
    },
    setup() {
        return {
            logoGooglePlaystore,
            logoAppleAppstore,
            globe
        };
    }
}
</script>

<style scoped>
.clear-button {
    width: 15rem;
}

.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
</style>