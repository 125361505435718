<template>
    <Loading v-if="isLoading" />
    <div v-else>
        <div v-for="product in productList" :key="product.id">
            <div class="galaxy-container">
                <img class="galaxy" src="/assets/images/galaxy.png" width="80" height="80">
            </div>
            <ion-card class="sub-card">
                <ion-card-header class="card-header">
                    <ion-card-title class="sub-title">{{ product.title }}</ion-card-title>
                </ion-card-header>
                <ion-card-content>
                    <p class="early-access">{{ $t('subscriptions.early_access') }}</p>
                    <div style="padding-top: 1.5rem;">
                        <div class="container">
                            <img src="/assets/images/idea.png" alt="idea" width="35" height="35">
                            <p class="align-bot">{{ $t('subscriptions.unlimited') }}</p>
                        </div>
                        <div class="container">
                            <img src="/assets/images/ideapaper.png" alt="ideapaper" width="35" height="35">
                            <p class="align-bot">{{ $t('subscriptions.new_content') }}</p>
                        </div>
                        <div class="container">
                            <img src="/assets/images/target.png" alt="target" width="35" height="35">
                            <p class="align-bot">{{ $t('subscriptions.review') }}</p>
                        </div>
                        <div class="container">
                            <img src="/assets/images/profit.png" alt="profit" width="35" height="35">
                            <p class="align-bot">{{ $t('subscriptions.prep') }}</p>
                        </div>
                        <p class="center-text free-text">{{ $t('subscriptions.trial') }}</p>
                        <p class="billing-period ion-padding-bottom">
                            {{ $t('subscriptions.new') }}
                        </p>
                    </div>
                    <p class=" free-text">{{ $t('subscriptions.week') }}</p>
                    <div class="justify-content">
                        <p class="price"> {{ product.pricingDetails.price }} </p>
                        <p class="price-unit">&nbsp;/ {{ product.pricingDetails.durationUnit }}</p>
                    </div>
                    <ion-button v-if="hasAccess" shape="round" fill="outline" disabled="true"
                        class="sub-button-disabled">{{ $t('subscriptions.button.already_subscribed') }}</ion-button>
                    <div v-else-if="webDetected">
                        <ion-spinner v-show="delayPaypal" class="center-text" />
                        <div v-show="!updatePaypal" id="paypal-button-container"></div>
                    </div>
                    <ion-button v-else-if="product.pricingDetails && product.pricingDetails.price"
                        @click="subscribe(product.id)" shape="round" class="sub-button">{{
                            $t('subscriptions.button.subscribe') }}</ion-button>
                    <p class="billing-period ion-padding-top">
                        {{ $t('subscriptions.billing') }} {{ storeName }}.
                    </p>
                    <p class="center-text"><a :href="subscriptionLink">{{ $t('subscriptions.manage') }}</a></p>
                </ion-card-content>
            </ion-card>
        </div>
        <div v-if="productList.length === 0">
            <ion-card class="sub-card">
                <ion-card-header class="card-header">
                    <h1 class="sub-title">{{ $t('subscriptions.unavailable.title') }}</h1>
                </ion-card-header>
                <ion-card-content>
                    <p class="product-description">{{ $t('subscriptions.unavailable.message') }}</p>
                </ion-card-content>
                <AppStoreLinks />
            </ion-card>
        </div>
        <p class="terms">{{ $t('subscriptions.policy.text') }} <a href="https://www.cloudcompany.ca/privacy"
                target="_blank"><ion-text color="secondary">{{ $t('subscriptions.policy.link') }}</ion-text></a>
        </p>
    </div>
</template>

<script lang="ts">
import Loading from '../../components/loading.vue';
import { useUserStore } from '../../store/userStore';
import { useGuestStore } from '../../store/guestStore';
import { Capacitor } from '@capacitor/core';
import GlobalIcon from '../../components/GlobalIcon.vue';
import AppStoreLinks from '../../components/AppStoreLinks.vue';
import { schoolOutline, bugOutline, wifiOutline, calendarNumberOutline } from 'ionicons/icons';
import { loadScript } from "@paypal/paypal-js"
import type { PayPalNamespace, PayPalButtonsComponent, OnApproveData, OnApproveActions } from '@paypal/paypal-js';
import { useRuntimeConfig } from 'nuxt/app';

const uStore = useUserStore();
const gStore = useGuestStore();

export default {
    emits: ['completedSubscription'],
    components: {
        Loading,
        GlobalIcon,
        AppStoreLinks
    },
    setup() {
        return {
            schoolOutline,
            bugOutline,
            wifiOutline,
            calendarNumberOutline,
            delayPaypal: ref(true),
            updatePaypal: ref(false),
        };
    },
    data() {
        return {
            subscriptions: [],
            isLoading: true,
            webDetected: Capacitor.getPlatform() === 'web',
            productList: []
        };
    },
    watch: {
        userId: {
            immediate: true,
            handler(newValue) {
                if (newValue && this.webDetected && !this.delayPaypal) {
                    this.updatePaypal = true;
                    this.delayPaypal = true;
                    setTimeout(() => {
                        this.updatePaypal = false;
                        this.delayPaypal = false;
                        this.updatePayPalButton();
                    }, 2000);
                }
            }
        }
    },
    mounted() {
        this.isLoading = true;
        if (Capacitor.isNativePlatform()) {
            this.$subscriptionService.waitForProductListInitialization().then(() => {
                this.loadSubscriptions();
                this.isLoading = false;
            });
        } else {
            //@ts-ignore
            this.productList = [{
                id: 1,
                title: "Explorer",
                freeTrialDetails: {
                    duration: 7,
                    durationUnit: "day(s)"
                },
                pricingDetails: {
                    duration: 1,
                    durationUnit: "month(s)",
                    price: "14.99"
                }
            }];
            setTimeout(() => {
                this.delayPaypal = false;
                this.createPaypalButton();
            }, 2000);
            this.isLoading = false;
        }
    },
    methods: {
        async loadSubscriptions() {
            try {
                this.productList = this.$subscriptionService.productList
            } catch (error) {
                console.error('Error loading subscriptions:', error);
            } finally {
                this.isLoading = false;
            }
        },
        async subscribe(product: number) {
            this.isLoading = true;
            try {
                await this.$subscriptionService.subscribeToProduct(product);
                setTimeout(async () => {
                    console.log('Subscribed to product:', product);
                    this.$emit('completedSubscription');
                    this.isLoading = false;
                }, 3000);
            } catch (error) {
                console.error('Error subscribing to product:', error);
                setTimeout(async () => {
                    await uStore.updateUserDetailsAndPrefs();
                    this.isLoading = false;
                }, 3000);
            }
        },
        async updatePayPalButton() {
            const container = document.getElementById('paypal-button-container');
            if (container) {
                container.innerHTML = '';
            }
            console.log("updating PayPal button");
            await this.createPaypalButton();
        },
        async createPaypalButton() {
            let paypalEnv: "sandbox" | "production" = useRuntimeConfig().public.PAYPAL.env as "sandbox" | "production";

            loadScript({
                clientId: useRuntimeConfig().public.PAYPAL.clientId,
                currency: "USD",
                environment: paypalEnv,
                vault: true,
                intent: "subscription",
            })
                .then((paypal: PayPalNamespace | null) => {
                    if (paypal === null) {
                        console.error("PayPal SDK script loaded but window.paypal is null");
                        return;
                    }

                    const planId = paypalEnv === "sandbox" ? 'P-5N384001DL1999803M5ELDBY' : 'P-3J433517Y1767263YM5D7SQA';
                    const userId = String(uStore.user?.id ?? gStore.signedUpID ?? 'Error retrieving user ID');
                    console.log('User ID:', userId);

                    const buttons: PayPalButtonsComponent | null = paypal.Buttons?.({
                        style: {
                            layout: 'horizontal',
                            color: 'gold',
                            shape: 'rect',
                            label: 'subscribe',
                            tagline: false,
                        },
                        createSubscription: function (data, actions) {
                            return actions.subscription.create({
                                plan_id: planId,
                                custom_id: userId,
                            });
                        },
                        onApprove: (data: OnApproveData, actions: OnApproveActions): Promise<void> => {
                            return new Promise((resolve) => {
                                this.isLoading = true;
                                setTimeout(async () => {
                                    console.log('Subscribed to product:', data.subscriptionID);
                                    this.$emit('completedSubscription');
                                    this.isLoading = false;
                                    resolve();
                                }, 3000);
                            });
                        },
                        onError: (err) => {
                            console.error("An error occurred during the subscription:", err);
                        },
                        onCancel: () => {
                            console.log("Subscription canceled by the user");
                        },
                    }) || null;

                    if (!buttons) {
                        throw new Error("PayPal Buttons component not loaded");
                    }

                    buttons
                        .render('#paypal-button-container')
                        .catch((error) => {
                            console.warn("Failed to render the PayPal Buttons, container unavailable.", error);
                        });
                })
                .catch((error) => {
                    console.error("Failed to load the PayPal JS SDK script", error);
                });
        },
    },
    computed: {
        userId() {
            return uStore.user?.id ?? gStore.signedUpID ?? 'Error retrieving user ID';
        },
        hasAccess() {
            return uStore.hasAccess;
        },
        storeName() {
            if (Capacitor.getPlatform() === 'android') {
                return 'Play Store'
            } else if (Capacitor.getPlatform() === 'ios') {
                return 'App Store'
            } else {
                return 'Not Supported'
            }
        },
        subscriptionLink() {
            if (Capacitor.getPlatform() === 'android') {
                return 'https://support.google.com/googleplay/answer/7018481?hl=en'
            } else if (Capacitor.getPlatform() === 'ios') {
                return 'https://support.apple.com/en-us/118428'
            } else {
                return 'https://www.cloudcompany.ca/privacy'
            }
        }
    }
}
</script>

<style scoped>
.galaxy-container {
    position: relative;
    display: flex;
    justify-content: center;
    transform: translateY(-50%);
    padding-bottom: .5rem;
    z-index: 1;
}

.galaxy {
    z-index: 1;
    position: absolute;
}

.align-bot {
    padding-top: 0.25rem;
    padding-left: 1rem;
    font-size: 1.05rem;
}

.container {
    display: flex;
    justify-content: flex-start;
    padding-left: 1.5rem;
    position: relative;
    margin-bottom: 0.4rem;
}

.early-access {
    background: #DD6E42;
    width: 30%;
    padding-top: 0.3125rem;
    padding-bottom: 0.15rem;
    margin: 0 auto;
    color: #FFFFFF;
    border-radius: 0.1875rem;
    font-size: 0.75rem;
    text-align: center;
}

.justify-content {
    display: flex;
    justify-content: center;
}

.price {
    font-size: 1.75rem;
    font-weight: bold;
}

.product-description {
    margin: 0 auto;
    margin-top: 2rem;
    font-size: 1.125rem;
    text-align: center;
}

.price-unit {
    font-size: 1.75rem;
    font-weight: bold;
}

.sub-button {
    margin: 0 auto;
    display: flex;
    font-size: 1.125rem;
}

.sub-button-disabled {
    --background: #433f46;
    display: flex;
    color: white;
    --border-color: #FFC857;
    font-size: 1.125rem;
}

.sub-card {
    margin: 0 auto;
    margin-top: 1.25rem;
    padding-top: 0.9375rem;
    width: 92%;
    border-radius: 0.9375rem;
    position: relative;
}

.sub-title {
    text-align: center;
    margin-top: 0.625rem;
}

.terms {
    text-align: center;
    font-size: 0.75rem;
    margin-top: 0.75rem;
}

.free-text {
    text-align: center;
    margin-top: 0.25rem;
    color: #DD6E42;
    text-decoration-thickness: 0.0625rem;
    text-align: center;
    font-size: 1.3rem;
}

.billing-period {
    text-align: center;
    justify-content: center;
    font-size: 0.635rem;
}

.app-links {
    align-items: center;
    justify-content: center;
    margin-top: 1.375rem;
}

#paypal-button-container {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.pp-GVHZH2BEFUB8L {
    text-align: center;
    border: none;
    border-radius: 1.5rem;
    min-width: 11.625rem;
    padding: 0 2rem;
    height: 2.625rem;
    font-weight: bold;
    background-color: #FFC857;
    color: #000000;
    font-family: "Helvetica Neue", Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.25rem;
    cursor: pointer;
}
</style>